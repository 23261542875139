import { useState, useRef, useEffect } from 'react'
import { keyBy, isFunction, isEmpty } from 'lodash'
import uuidv4 from 'uuid'
import axios from 'axios'
import { notification, uploadToS3 } from '@edulastic/common'
import { segmentApi } from '@edulastic/api'
import {
  fileUploadStatusTypes,
  UPLOAD_CANCELLED_BY_USER_MESSAGE,
} from '../constants'

/**
 * Custom React hook for handling file uploads with drag-and-drop and input selection.
 *
 * @param {Object} options Configuration options for the uploader.
 * @param {React.RefObject} options.fileDropAreaRef Reference to the file drop area element.
 * @param {string} options.s3Folder Target folder in S3 for uploaded files.
 * @param {number} options.maxFileSize Maximum allowed file size in MB.
 * @param {string[]} options.allowedFileTypes Array of allowed file types (e.g., ["image/jpeg", "application/pdf"]).
 * @param {number} options.maxFilesCount Maximum number of files allowed to be uploaded.
 * @param {string} options.uploadType Optional upload type for BE upload flow.
 * @param {function} options.resetAllData Callback function to reset all upload data.
 * @param {function} options.unsupportedFileCallback Callback function for unsupported file types.
 * @param {function} options.fileUploadProgressCallback Callback function for upload progress updates.
 * @param {function} options.fileUploadFinishCallback Callback function when all uploads are completed successfully.
 * @param {function} options.uploadCancelCallback Callback function when an upload is canceled.
 * @param {function} options.fileDeleteCallback Callback function for deleting a file.
 * @param {function} options.maxFileCountExceededCallback Callback function when the maximum file count is exceeded.
 * @returns {Object} An object containing functions and data related to file uploads.
 */

export const useFileUploader = ({
  fileDropAreaRef,
  s3Folder,
  maxFileSize,
  allowedFileTypes,
  maxFilesCount,
  uploadType,
  resetAllData,
  unsupportedFileCallback,
  maxFileSizeExceedCallback,
  fileUploadProgressCallback,
  fileUploadFinishCallback,
  uploadCancelCallback,
  fileDeleteCallback,
  maxFileCountExceededCallback,
  eventSource,
}) => {
  const filesDataRef = useRef({})
  const fileInputRef = useRef(null)
  const [filesData, setFilesData] = useState({})

  const isValidFile = (file) => {
    const size = file.size / 1024 / 1024
    if (!allowedFileTypes.includes(file.type)) {
      if (isFunction(unsupportedFileCallback)) {
        unsupportedFileCallback()
      } else {
        notification({
          type: 'warn',
          messageKey: 'unsupportedFileType',
        })
      }
      return false
    }
    if (size > maxFileSize) {
      if (isFunction(maxFileSizeExceedCallback)) {
        maxFileSizeExceedCallback()
      } else {
        notification({
          type: 'warn',
          messageKey: 'maximumFileSizeExceeded',
        })
      }
      return false
    }
    return true
  }

  const updateFilesInfo = (fileUUID, updateData) => {
    const updatedFilesData = {
      ...filesDataRef.current,
      [fileUUID]: {
        ...filesDataRef.current[fileUUID],
        ...updateData,
      },
    }
    setFilesData(updatedFilesData)
    filesDataRef.current = updatedFilesData
  }

  const trackEvent = (data) => {
    eventSource && segmentApi.genericEventTrack(`${eventSource}:Upload`, data)
  }

  const fileInputClickHandler = (e) => {
    trackEvent({
      importType: 'Device',
      action: 'click',
    })

    if (fileInputRef.current) {
      fileInputRef.current.click()
      e.target.blur()
    }
  }

  const onUpdateUploadProgressPercent = (uuid) => (e) => {
    if (filesDataRef.current?.[uuid]) {
      const uploadPercent = Math.round((e?.loaded * 100) / e?.loaded)
      updateFilesInfo(uuid, { uploadPercent })
    }
  }

  const setCancelCallbackFunction = (uuid) => (fn) => {
    if (filesDataRef.current?.[uuid]) {
      updateFilesInfo(uuid, { cancelCallbackFunc: fn })
    }
  }

  const onUploadComplete = () => {
    const isAllFilesUploaded = Object.values(
      filesDataRef.current || {}
    ).every((file) =>
      [fileUploadStatusTypes.SUCCESS, fileUploadStatusTypes.FAILED].includes(
        file.status
      )
    )
    if (isAllFilesUploaded) {
      if (isFunction(fileUploadFinishCallback)) {
        fileUploadFinishCallback({
          filesData: Object.values(filesDataRef.current || {}),
        })
      }
    }
  }

  const onDelete = (fileUUID) => {
    const updatedFilesData = { ...filesDataRef.current }
    delete updatedFilesData[fileUUID]
    setFilesData(updatedFilesData)
    filesDataRef.current = updatedFilesData
    if (isFunction(fileDeleteCallback)) {
      fileDeleteCallback({ fileUUID })
    }
    onUploadComplete()
  }

  const cancelUpload = (fileUUID) => {
    const cancelFunction = filesDataRef.current?.[fileUUID]?.cancelCallbackFunc
    if (typeof cancelFunction === 'function') {
      cancelFunction(UPLOAD_CANCELLED_BY_USER_MESSAGE)
      const updatedFilesData = { ...filesDataRef.current }
      delete updatedFilesData[fileUUID]
      setFilesData(updatedFilesData)
      filesDataRef.current = updatedFilesData
      if (isFunction(uploadCancelCallback)) {
        uploadCancelCallback({ fileUUID })
      }
    }
  }

  const handleUpload = async (file) => {
    try {
      updateFilesInfo(file.uuid, { status: fileUploadStatusTypes.IN_PROGRESS })
      const cdnUrl = await uploadToS3(
        file,
        s3Folder,
        onUpdateUploadProgressPercent(file.uuid),
        setCancelCallbackFunction(file.uuid),
        undefined,
        undefined,
        undefined,
        uploadType
      )
      updateFilesInfo(file.uuid, {
        status: fileUploadStatusTypes.SUCCESS,
        cdnUrl,
      })
    } catch (error) {
      trackEvent({
        importType: 'Device',
        action: 'uploadFailed',
        reason: error?.message,
      })

      if (
        axios.isCancel(error) &&
        error?.message === UPLOAD_CANCELLED_BY_USER_MESSAGE
      ) {
        return
      }
      updateFilesInfo(file.uuid, {
        status: fileUploadStatusTypes.FAILED,
        errorMessage: error?.message || '',
      })
    } finally {
      onUploadComplete()
    }
  }

  const uploadFiles = (files) => {
    if (!files?.length) {
      return
    }
    const validFiles = files
      .map((file) => {
        if (!isValidFile(file)) {
          return false
        }
        file.uuid = uuidv4()
        return file
      })
      .filter((file) => file)
      .slice(0, maxFilesCount)

    const _filesData = validFiles.map((file) => ({
      uuid: file.uuid,
      name: file.name,
      type: file.type,
      size: file.size,
      status: fileUploadStatusTypes.NOT_STARTED,
      uploadPercent: 0,
      cancelCallbackFunc: null,
      cdnUrl: '',
      errorMessage: '',
    }))
    const filesDataKeyedByUUID = keyBy(_filesData, 'uuid')
    filesDataRef.current = filesDataKeyedByUUID
    setFilesData(filesDataKeyedByUUID)

    if (validFiles.length) {
      if (isFunction(fileUploadProgressCallback)) {
        fileUploadProgressCallback()
      }
      validFiles.forEach(handleUpload)
    }
  }

  const handleFileInputChange = (e) => {
    trackEvent({
      importType: 'Device',
      action: 'picked',
    })

    const { files } = e?.target || {}
    if (!isEmpty(files)) {
      const filesArr = Object.keys(files).map((key) => files[key])
      uploadFiles(filesArr)
    }
  }

  const resetFileInputValue = (e) => {
    e.target.value = null
  }

  const handleFileDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event?.dataTransfer?.files
    if (!isEmpty(files)) {
      const filesArr = Object.keys(files).map((key) => files[key])
      const existingUploadedFilesCount =
        (Object.values(filesDataRef.current || {}) || []).filter(
          (file) => file.status === fileUploadStatusTypes.SUCCESS
        )?.length || 0
      if (
        filesArr?.length > maxFilesCount ||
        filesArr?.length + existingUploadedFilesCount > maxFilesCount
      ) {
        if (isFunction(maxFileCountExceededCallback)) {
          maxFileCountExceededCallback()
        } else {
          notification({
            type: 'warn',
            messageKey: 'maximumFileCountExceeded',
          })
        }
        return
      }

      trackEvent({
        importType: 'Device',
        action: 'drop',
      })
      uploadFiles(filesArr)
    }
  }

  const handleFileFragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  useEffect(() => {
    if (fileDropAreaRef?.current) {
      fileDropAreaRef.current.addEventListener('drop', handleFileDrop)
      fileDropAreaRef.current.addEventListener('dragover', handleFileFragOver)
    }
    return () => {
      if (fileDropAreaRef?.current) {
        fileDropAreaRef.current.removeEventListener('drop', handleFileDrop)
        fileDropAreaRef.current.removeEventListener(
          'dragover',
          handleFileFragOver
        )
      }
    }
  }, [fileDropAreaRef, maxFilesCount])

  useEffect(() => {
    if (resetAllData) {
      setFilesData({})
      filesDataRef.current = {}
    }
  }, [resetAllData])

  return {
    handleFileInputChange,
    cancelUpload,
    fileInputClickHandler,
    resetFileInputValue,
    onDelete,
    isUploadInProgress: !!(Object.values(filesData || {})?.length > 0),
    fileInputRef,
    filesData: Object.values(filesData || {}),
  }
}
