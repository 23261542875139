const userContextTypes = {
  RECENT_PLAYLISTS: 'RECENT_PLAYLISTS',
  LAST_ACCESSED_PLAYLIST: 'LAST_ACCESSED_PLAYLIST',
  STANDARDS_MASTERY_FILTERS: 'STANDARDS_MASTERY_FILTERS',
  SINGLE_ASSESSMENT_REPORT_FILTERS: 'SINGLE_ASSESSMENT_REPORT_FILTERS',
  MULTIPLE_ASSESSMENT_REPORT_FILTERS: 'MULTIPLE_ASSESSMENT_REPORT_FILTERS',
  USER_CUSTOM_KEYPADS: 'USER_CUSTOM_KEYPADS',
  LAST_ACCESSED_LINKED_USER_ACCOUNT: 'LAST_ACCESSED_LINKED_USER_ACCOUNT',
  VQ_USAGE: 'VQ_USAGE',
  DASHBOARD_VERSION: 'DASHBOARD_VERSION',
  VIEWED_TUTORIALS: 'VIEWED_TUTORIALS',
}

const userEngagementTypes = {
  ASSIGNMENT_SETTINGS_MODE_DATA: 'ASSIGNMENT_SETTINGS_MODE_DATA',
}

module.exports = {
  userContextTypes,
  userEngagementTypes,
}
