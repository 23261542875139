import { useState, useRef, useEffect } from 'react'
import uuidv4 from 'uuid'
import { isFunction, isEmpty } from 'lodash'
import { fileApi, segmentApi } from '@edulastic/api'
import { notification } from '@edulastic/common'
import { fileTypes } from '@edulastic/constants'
import { fileUploadStatusTypes } from '../constants'

/**
 * Custom React hook for handling file uploads from Google Drive.
 *
 * @param {Object} options Configuration options for the uploader.
 * @param {function} options.resetAllData Function to reset all upload data.
 * @param {function} options.onAuthFailedCallback Callback function for authentication failures.
 * @param {function} options.onDriveFileSelectCallback Callback function when a file is selected from Drive.
 * @param {function} options.fileUploadProgressCallback Callback function for upload progress updates.
 * @param {function} options.fileUploadFinishCallback Callback function when the upload is completed successfully.
 * @param {function} options.fileUploadFailedCallback Callback function when the upload fails.
 * @param {function} options.fileDeleteCallback Callback function for deleting a file.
 * @param {function} options.uploadCancelCallback Callback function for canceling an upload.
 * @returns {Object} An object containing functions and data related to file uploads.
 */

export const useFileUploadFromDrive = ({
  maxFileSize,
  s3Folder,
  uploadType,
  resetAllData,
  onAuthFailedCallback,
  onDriveFileSelectCallback,
  fileUploadProgressCallback,
  fileUploadFinishCallback,
  fileUploadFailedCallback,
  fileDeleteCallback,
  uploadCancelCallback,
  maxFileSizeExceedCallback,
  eventSource,
}) => {
  const [fileData, setFileData] = useState({})
  const isUploadCancelledRef = useRef(false)

  const onDelete = (fileUUID) => {
    setFileData({})
    if (isFunction(fileDeleteCallback)) {
      fileDeleteCallback({ fileUUID })
    }
    if (isFunction(fileUploadFinishCallback)) {
      fileUploadFinishCallback({
        filesData: [],
      })
    }
  }

  const uploadComplete = (_fileData) => {
    if (isFunction(fileUploadFinishCallback)) {
      fileUploadFinishCallback({
        filesData: [_fileData],
      })
    }
  }

  const cancelUpload = (fileUUID) => {
    isUploadCancelledRef.current = true
    setFileData({})
    if (isFunction(uploadCancelCallback)) {
      uploadCancelCallback({ fileUUID })
    }
  }

  const onAuthFailed = () => {
    if (isFunction(onAuthFailedCallback)) {
      onAuthFailedCallback()
    }
  }
  const trackEvent = (data) => {
    eventSource && segmentApi.genericEventTrack(`${eventSource}:Upload`, data)
  }

  const onSelect = async ({ action, docs, token }) => {
    trackEvent({
      importType: 'Drive',
      action,
    })

    const [selectedFile] = docs || []
    const { id, sizeBytes: size, mimeType } = selectedFile || {}
    let { name } = selectedFile || {}

    if (size / 1024 / 1024 > maxFileSize) {
      if (isFunction(maxFileSizeExceedCallback)) {
        maxFileSizeExceedCallback()
      } else {
        notification({
          type: 'warn',
          messageKey: 'maximumFileSizeExceeded',
        })
      }
      return false
    }

    // google docx don't have extension
    if (mimeType === fileTypes.GOOGLE_DOC) {
      name = `${name}.docx`
    }

    let _fileData = {
      id,
      name,
      size,
      mimeType,
      uuid: uuidv4(),
      uploadPercent: 50,
      status: fileUploadStatusTypes.IN_PROGRESS,
      cdnUrl: '',
      errorMessage: '',
    }
    try {
      if (action === 'picked' && selectedFile?.id && token) {
        setFileData(_fileData)
        if (isFunction(onDriveFileSelectCallback)) {
          onDriveFileSelectCallback({ selectedFile })
        }
        if (isFunction(fileUploadProgressCallback)) {
          fileUploadProgressCallback()
        }
        const uploadedFileData = await fileApi.uploadFromDrive({
          id,
          token,
          name,
          size,
          mimeType,
          folderName: s3Folder,
          uploadType,
        })
        _fileData = {
          ..._fileData,
          name,
          uploadPercent: 100,
          cdnUrl: uploadedFileData?.cdnLocation || '',
          status: fileUploadStatusTypes.SUCCESS,
        }
        if (isUploadCancelledRef.current) {
          return
        }
        setFileData(_fileData)
        uploadComplete(_fileData)
      }
    } catch (error) {
      trackEvent({
        importType: 'Drive',
        action: 'uploadFailed',
        reason: error?.message,
      })

      if (isUploadCancelledRef.current) {
        return
      }
      _fileData = {
        ..._fileData,
        cdnUrl: '',
        status: fileUploadStatusTypes.FAILED,
        errorMessage: error?.message || '',
      }
      setFileData(_fileData)
      uploadComplete(_fileData)
      if (isFunction(fileUploadFailedCallback)) {
        fileUploadFailedCallback(_fileData)
      }
    } finally {
      isUploadCancelledRef.current = false
    }
  }

  useEffect(() => {
    if (resetAllData) {
      setFileData({})
    }
  }, [resetAllData])

  return {
    onAuthFailed,
    onSelect,
    onDelete,
    cancelUpload,
    isUploadInProgress: !isEmpty(fileData),
    filesData: !isEmpty(fileData) ? [fileData] : [],
  }
}
