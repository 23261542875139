const { SMART_FILTERS } = require("./filters")

const helpCenterUrl = 'https://support.goguardian.com/s/'

const sessionFilters = {
  TEST_FILTER: 'filters[testList]',
  TEST_SORT: 'sortBy[testList]',
  TEST_ITEM_FILTER: 'filters[itemList]',
  TEST_ITEM_SORT: 'sortBy[itemList]',
  PLAYLIST_FILTER: 'filters[playList]',
  PLAYLIST_SORT: 'sortBy[playList]',
  DASHBOARD_SEARCH: 'contentSearch[dashboard]',
  [`SEARCH_FILTER_${SMART_FILTERS.ENTIRE_LIBRARY}`]: `filters[${SMART_FILTERS.ENTIRE_LIBRARY}]`,
  [`SEARCH_FILTER_${SMART_FILTERS.AUTHORED_BY_ME}`]: `filters[${SMART_FILTERS.AUTHORED_BY_ME}]`,
  [`SEARCH_FILTER_${SMART_FILTERS.SHARED_WITH_ME}`]: `filters[${SMART_FILTERS.SHARED_WITH_ME}]`,
  [`SEARCH_FILTER_${SMART_FILTERS.CO_AUTHOR}`]: `filters[${SMART_FILTERS.CO_AUTHOR}]`,
  [`SEARCH_FILTER_${SMART_FILTERS.PREVIOUSLY_USED}`]: `filters[${SMART_FILTERS.PREVIOUSLY_USED}]`,
  [`SEARCH_FILTER_${SMART_FILTERS.FAVORITES}`]: `filters[${SMART_FILTERS.FAVORITES}]`,
  [`SEARCH_FILTER_${SMART_FILTERS.FOLDERS}`]: `filters[${SMART_FILTERS.FOLDERS}]`,
  ITEM_LIBRARY_FILTER_EXPANDED: 'filterCategoryExpanded[itemLibrary]',
  TEST_LIBRARY_FILTER_EXPANDED: 'filterCategoryExpanded[testLibrary]',
  PLAYLIST_LIBRARY_FILTER_EXPANDED: 'filterCategoryExpanded[playlistLibrary]',
  LAST_VISITED_LIBRARY: 'lastVisitedLibrary',
}

const LIBRARY_TYPES = {
  ITEM: 'item',
  TEST: 'test',
  PLAYLIST: 'playlist'
}

const WEEK_DAYS = {
  MONDAY: 'MON',
  TUESDAY: 'TUE',
  WEDNESDAY: 'WED',
  THURSDAY: 'THU',
  FRIDAY: 'FRI',
  SATURDAY: 'SAT',
  SUNDAY: 'SUN',
}

const hour12inMiliSec = 12 * 60 * 60 * 1000

const ATTEMPT_WINDOW_DEFAULT_START_TIME = '06:00 AM'
const ATTEMPT_WINDOW_DEFAULT_END_TIME = '06:00 PM'

const STUDENT_ATTEMPT_TIME_WINDOW = 'attemptWindow'

const TEN_DAYS_IN_MILLISECONDS = 10 * 24 * 60 * 60 * 1000
const ONE_HOURS_IN_MILLISECONDS = 3600000
const ONE_MINUTES_IN_MILLISECONDS = 60000
const TOTAL_MINUTE_IN_HOURS = 60
const TOTAL_HOURS_IN_ONE_DAY = 24
const TOTAL_HOURS_IN_HALF_DAY = 12
const ANTE_MERIDIEM = 'AM'
const POST_MERIDIEM = 'PM'
const THREE_MONTH_IN_MILLISECONDS = 90 * 24 * 60 * 60 * 1000
const ONE_MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000

const PASSWORD_KEY = 'password'

const PUBLIC_URL_IDENTIFIER = '/public/'

const PEAR_ASSESSMENT_CERTIFIED_NAME = 'Pear Assessment Certified'

const SHARE_ENTITY_TYPE = {
  TEST: 'TEST',
  PLAYLIST: 'PLAYLIST',
}

module.exports = {
  helpCenterUrl,
  sessionFilters,
  LIBRARY_TYPES,
  WEEK_DAYS,
  ATTEMPT_WINDOW_DEFAULT_START_TIME,
  ATTEMPT_WINDOW_DEFAULT_END_TIME,
  STUDENT_ATTEMPT_TIME_WINDOW,
  hour12inMiliSec,
  ONE_HOURS_IN_MILLISECONDS,
  ONE_MINUTES_IN_MILLISECONDS,
  TOTAL_MINUTE_IN_HOURS,
  TOTAL_HOURS_IN_ONE_DAY,
  TOTAL_HOURS_IN_HALF_DAY,
  ANTE_MERIDIEM,
  POST_MERIDIEM,
  PASSWORD_KEY,
  PUBLIC_URL_IDENTIFIER,
  THREE_MONTH_IN_MILLISECONDS,
  ONE_MONTH_IN_MILLISECONDS,
  TEN_DAYS_IN_MILLISECONDS,
  PEAR_ASSESSMENT_CERTIFIED_NAME,
  SHARE_ENTITY_TYPE,
}
